import React from 'react'
import img6 from "../../../images/cashinhand.png"
import "./dashcards.css"


const Empty = () => {
  return (
    <div>
      <div className='empty'>
        </div>
    </div>
  )
}

export default Empty