import React, { useState, useEffect } from 'react';
import './Carousel.css';
import apply from "../../images/elite2.jpg"
import apply1 from "../../images/elite3.jpg"
import apply3 from "../../images/elite10.jpg"
import apply2 from "../../images/elite5.jpg"
import { Link } from 'react-router-dom';

const FadeCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
  apply,
  apply3,
  apply2,
  ];

    const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      // behavior: 'smooth', // Add smooth scrolling behavior
    });
  };
    
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="carousel">
  {images.map((image, index) => (
    <div
      key={index}
      className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
      style={{ backgroundImage: `url(${image})` }}
    ></div>
  ))}
    <div className="carousel-content">
         <div className='landing-carousel'>
        <div className='mamlandingcontent_main'>
        <p className='mamlandingcontent_main-paragraph'>Order now a <span className='Landingspancolor1'>professional <br></br>
        Website or Mobile</span> application</p>
        </div>
        <div className='mamealanding'>
        <div className='memlandcontent'>
         <p>Take your business venture to a higher level, upgrade for greater success</p>
            </div>
        <div className='memlandcontenti'>
         <p>Design, development, hosting, maintaince, updates and 24/7 support</p>
        </div>
        <div className='memlandcontente'>
         <p>Pay in installments <span className='landcolrone'>of $50 per month</span></p>
        </div>
        <Link to="/signup" className='ton tin' onClick={scrollToTop}>Order now!</Link>
          </div>
       </div>
    </div>
    </div>
  );
};

export default FadeCarousel;