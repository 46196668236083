import React from 'react'
import { Link } from 'react-router-dom'
import MainNav from '../navbar/navbar'
import "./Landingnav.css"
import FadeCarousel from './Carousel'

const Landingnav = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      // behavior: 'smooth', // Add smooth scrolling behavior
    });
  };

  return (
    
    <div>
    <div className='main-carousel'>
        <FadeCarousel />
    </div>
    <div className='mathemlanding'>
      <div className='mamlanding'>
        <MainNav />
        </div>
      </div>
    </div>
  )
}

export default Landingnav